import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import App from './Pages/Home';
import About from './Pages/About';
import Websites from './Pages/Websites';
import Applications from './Pages/Applications';
import Contact from './Pages/Contact';

import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  { path: "/", element: <App /> },
  { path: "/about", element: <About /> },
  { path: "/websites", element: <Websites /> },
  { path: "/applications", element: <Applications /> },
  { path: "/contact", element: <Contact /> },
  { path: "/*", element: <App /> },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
