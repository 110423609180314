import { useEffect, useState, useRef } from 'react'
import { useSpring, animated } from '@react-spring/web'

// import { Dialog } from '@headlessui/react'

import Header from './Header';

export default function Hero() {
  const chessBoardRef = useRef(null);
  const whiteRookRef = useRef(null);
  const blackRookRef = useRef(null);
  const [whiteRookData, setWhiteRookData] = useState({
    prevX: 800,
    prevY: 0,
    direction: false
  });

  const [blackRookData, setBlackRookData] = useState({
    prevX: 0,
    prevY: 0,
    direction: true
  });
  const [whiteRookMoving, setWhiteRookMoving] = useState(true);

  const [whiteAnim, whiteAnimApi] = useSpring(() => ({
    from: { x: 800, y: 0 },
  }));

  const [blackAnim, blackAnimApi] = useSpring(() => ({
    from: { x: 0, y: 0 },
  }));

  useEffect(() => {
    const moveRook = (api, rookData, x, y) => {
      api.start({
        from: {
          x: rookData.prevX,
          y: rookData.prevY
        },
        to: {
          x: rookData.direction ? x : rookData.prevX,
          y: rookData.direction ? rookData.prevY : y
        },
      });
    };

    //Implementing the setInterval method
    const interval = setInterval(() => {
      if (!chessBoardRef.current) return;
      if (!whiteRookRef.current) return;
      if (!blackRookRef.current) return;
      
      
      
      if (whiteRookMoving) {
        let newRowColPosition = Math.round(Math.random() * 8);
        let newRookX = Math.floor(newRowColPosition * (chessBoardRef.current.offsetWidth - whiteRookRef.current.offsetWidth - 40) / 8)
        let newRookY = Math.floor(newRowColPosition * (chessBoardRef.current.offsetHeight - whiteRookRef.current.offsetHeight - 40) / 8)
        
        while (Math.abs(newRookX - whiteRookData.prevX) < whiteRookRef.current.offsetWidth * 2) {
          newRowColPosition = Math.round(Math.random() * 8);
          newRookX = Math.floor(newRowColPosition * (chessBoardRef.current.offsetWidth - whiteRookRef.current.offsetWidth - 40) / 8)
        }

        while (Math.abs(newRookY - whiteRookData.prevY) < whiteRookRef.current.offsetHeight * 2) {
          newRowColPosition = Math.round(Math.random() * 8);
          newRookY = Math.floor(newRowColPosition * (chessBoardRef.current.offsetHeight - whiteRookRef.current.offsetHeight - 40) / 8)
        }

        moveRook(whiteAnimApi, whiteRookData, newRookX, newRookY);
        setWhiteRookData({
          prevX: whiteRookData.direction ? newRookX : whiteRookData.prevX,
          prevY: whiteRookData.direction ? whiteRookData.prevY : newRookY,
          direction: !whiteRookData.direction
        })
      } 
      
      else {
        const newRookX = whiteRookData.prevX
        const newRookY = whiteRookData.prevY
        moveRook(blackAnimApi, blackRookData, newRookX, newRookY);
        
        setBlackRookData({
          prevX: blackRookData.direction ? newRookX : blackRookData.prevX,
          prevY: blackRookData.direction ? blackRookData.prevY : newRookY,
          direction: !blackRookData.direction
        })
      }

      setWhiteRookMoving(p => !p);
    }, 1000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [blackAnimApi, blackRookData, whiteAnimApi, whiteRookData, whiteRookMoving]);


  return (
    <div className="bg-white">
      <Header></Header>



      <div ref={chessBoardRef} className="relative px-6 lg:px-8 background shadow-md border border-gray-300">
        {/* White rook */}
        <animated.img
          ref={whiteRookRef}
          className={`flex items-center h-20 opacity-10 w-auto absolute z-10 lg:h-20`}
          style={{
            ...whiteAnim,
          }}
          src="/Rook_watermark.png"
          alt=""
        />

        {/* Black rook */}
        <animated.img
          ref={blackRookRef}
          className={`flex items-center h-20 opacity-20 w-auto absolute z-10 lg:h-20`}
          style={{
            ...blackAnim,
          }}
          src="/Rook_watermark.png"
          alt=""
        />
        <div className="mx-auto max-w-2xl pt-32 pb-24 sm:pt-48 lg:pt-56">
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Moving businesses onto the web
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Rook Solutions provides quality web-based products to meet businesses needs.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="/contact"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Get in touch
              </a>
              <a href="/about" className="text-sm font-semibold leading-6 text-gray-900">
                Learn more <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>
      </div>


    </div>
  )
}
