
export default function Footer() {

  return (
    <div className="overflow-hidden bg-gray-800 text-gray-500 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            {"Made with <3 by "} <a href="http://kanejackson.com">Kane Jackson, </a><a href="/">Rook Solutions </a>
          </div>
        </div>
      </div>
    </div>

  )
}
