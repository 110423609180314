import React, { useRef } from 'react';
import '../App.css';
import emailjs from '@emailjs/browser';
import Header from '../components/Header';
import Footer from '../components/Footer';

function Contact() {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_hwtx6yl', 'template_dcjqye8', form.current, 'auEZ9YqZNL9QIWVVW')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  };


  return (
    <div className="App">
      <Header></Header>

      <div className="relative px-6 lg:px-8 background shadow-lg">
        <div className="mx-auto max-w-2xl pt-32 pb-24 sm:pt-48 lg:pt-36">
          <div className="rounded-3xl shadow-lg border border-gray-300 p-12 mb-16 text-left bg-white">

            <h2 className="text-4xl font-bold tracking-tight text-center">Get in touch</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600 text-center">Want to know what we can do for you? Shoot us an inquiry and we'll make it happen.</p>

            <form ref={form} onSubmit={sendEmail} className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label className="block text-sm font-medium leading-6 text-gray-900">Email Address</label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input type="email" name="reply_to" className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" placeholder="janesmith" />
                  </div>
                </div>
              </div>

              <div className="col-span-full">
                <label className="block text-sm font-medium leading-6 text-gray-900">Your inquiry</label>
                <div className="mt-2">
                  <textarea name="message" rows="3" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
                </div>
              </div>

              <input 
                type="submit"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                value={'Get the ball rolling!'}
              >
                
              </input>

            </form>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default Contact;
