import '../App.css';
import Header from '../components/Header';
import Footer from '../components/Footer';

const websites = [
  { 
    name: "\"Bars\" Personal Webpage", 
    href: "https://kanejackson.com",
    image: "/kanejackson.png",
    description: ""
  }, { 
    name: "\"Digital Ocean\" Personal Portfolio", 
    href: "/solutions",
    description: "Another lil website hehe"},
]

function Websites() {
  return (
    <div className="App">
      <Header></Header>

      <div className="relative px-6 lg:px-8 background shadow-md border border-gray-300">
        <div className="mx-auto max-w-6xl pt-32 pb-24 sm:pt-48 lg:pt-36 text-left">
          Our services encompass the full development cycle—from 
          initial consultation and wireframe design to deployment and ongoing support. Our team is skilled in the latest 
          web technologies, ensuring responsive, fast, and SEO-optimized websites that drive engagement and deliver 
          results. Let us bring your vision to life with a seamless, innovative, and user-friendly online experience.
        </div>
      </div>

      <div>
        <div className="mx-auto max-w-6xl">
          {websites.map((website) => (
            <div className="rounded-xl border border-gray-300 mt-16 p-2 text-left bg-white flex flex-col-reverse lg:flex-row justify-between">
              <div className="w-200 m-12">
                <h2 className="text-4xl font-bold tracking-tight">{website.name}</h2>
                <p className="my-6 text-lg leading-8 text-gray-600">{website.description}</p>
                <a
                  href={website.href}
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Visit website <span aria-hidden="true">→</span>
                </a>
              </div>
              <img
                className="w-full right-0 rounded-xl lg:w-2/5"
                src={website.image}
                alt=""
              />
            </div>
          ))}
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
}

export default Websites;
