import '../App.css';
import Hero from '../components/Hero';
import Features from '../components/Features';
import Products from '../components/Products';
import Footer from '../components/Footer';

function App() {
  return (
    <div className="App bg-white">
      <Hero></Hero>
      <Features></Features>
      <Products></Products>
      <Footer></Footer>
    </div>
  );
}

export default App;
